@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css';
// GEBERAL
.tikbox-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

// HOME
.tikbox-assets {
  background-color: #f3f3f3;
  &__content {
    display: flex;
    height: 100%;

    .assets-container {
      padding: 16px 20px;

      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        h2.title {
          font-size: 24px;
          font-weight: 700;
        }
        .status-filter {
          display: flex;
          .dropdown-container {
            width: 199px;
            height: 48px;
            border: 1px solid #cacbd2;
            font-weight: 400;
            border-radius: 8px;
            font-size: 12px;
            position: relative;
            cursor: pointer;
            padding: 10px 12px 10px 16px;
            margin-right: 24px;

            .status-text {
              width: 100%;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            &__dropdown {
              position: absolute;
              top: 50px;
              left: 0;
              width: 100%;
              background-color: white;
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
              display: none;
              z-index: 2;
              padding: 16px;

              ul {
                padding: 0;
                li {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: left;
                  color: black;
                  height: 30px;

                  &:hover {
                    background-color: #f8f8f8;
                  }
                }
              }

              &.open {
                display: block;
              }
            }
          }
          .search-input {
            position: relative;

            input {
              width: 297px;
              height: 48px;
              border: 1px solid #cacbd2;
              border-radius: 8px;
              outline: none;
              padding: 16px;
            }

            svg {
              position: absolute;
              right: 16px;
              top: 14px;
            }
          }
        }
      }
      .assets-table {
        &__header {
          font-weight: bold;
          position: relative;
          padding-bottom: 3px;
          height: 47px;
          border: 1px solid #e8e8e8;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &__row {
          display: flex;
          margin-bottom: 10px;
          width: 100%;
          align-items: center;
          height: 100%;
          padding-left: 20px;
          padding-right: 20px;
          .tikbox-col {
            width: 15%;
            display: flex;
            align-items: center;
          }
          .tikbox-col:first-child {
            width: 40%;
            img {
              width: 40px;
            }
          }
          .tikbox-col {
            &.name {
              display: flex;
              align-items: center;
              span {
                font-weight: 400;
                font-size: 14px;
                text-decoration: underline;
              }
            }
            &__content {
              width: 100%;
              .title {
                display: none;
              }
              .content {
                height: 24px;
                border-radius: 8px;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              &.signed {
                .content {
                  color: rgb(43, 219, 40);
                  border: 1px solid rgb(43, 219, 40);
                  background-color: #e4fdde;
                }
              }
              &.asserted {
                .content {
                  border: 1px solid #1f8505;
                  background-color: #eeffea;
                  color: #1f8505;
                  width: 75px;
                }
              }
              &.draft {
                .content {
                  border: 1px solid #848484;
                  background-color: #fff;
                  color: #848484;
                  width: 52px;
                }
              }
              &.deleted {
                .content {
                  border: 1px solid #848484;
                  background-color: #da1919;
                  color: #fff;
                  width: 70px;
                }
              }
              &.gen-ai {
                .content {
                  border: 1px solid #493cff;
                  background-color: #493cff;
                  color: #fff;
                  width: 70px;
                }
              }
            }
            .agreement-seperator {
              content: '';
              height: 50px;
              width: 2px;
              background-color: #e8e8e8;
              margin-left: 3px;
              margin-right: 7px;
            }
            .button {
              border: 1px solid #0daa85;
              padding: 5px;
              width: 32px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #0daa85;
              height: 32px;
              border-radius: 8px;
              margin-right: 5px;
              @include transition-ease;

              span {
                font-size: 12px;
                font-weight: 700;
              }
              &.edit {
                background-color: #0daa85;
                span {
                  color: #fff;
                }
              }
              &.delete {
                background-color: rgb(190, 8, 8);
                border: none;
                svg path {
                  stroke: #fff;
                }
                span {
                  color: #fff;
                }
              }
              &.search {
                background-color: rgb(255, 255, 255);
                border-color: #0f27fd;

                svg {
                  width: 20px !important;
                }
                svg path {
                  stroke: #0f27fd;
                  fill: #0f27fd;
                }
                span {
                  color: #fff;
                }
              }
              &.license {
                background-color: #0f27fd;
                border-color: #0f27fd;

                svg {
                  width: 20px !important;
                }
                svg path {
                  stroke: #fff;
                  fill: #fff;
                }
                span {
                  color: #fff;
                }
              }
              &.asserted {
                width: 32px;
              }

              &:hover {
                opacity: 0.9;
              }
            }
          }
        }
        &__body {
          border-left: 1px solid #e8e8e8;
          border-right: 1px solid #e8e8e8;
          .assets-table__row {
            height: 64px;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }

      .media-bg {
        width: 120px !important;
        height: 65px !important;
        background: rgb(205, 205, 205);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.search-engine-modal {
  .search-loader {
    width: 100%;
    text-align: center;
    p {
      margin: 0 auto;
    }
  }
  .search-results {
    display: flex;
    flex-wrap: wrap;
    max-height: 600px;
    overflow-y: auto;

    &__column {
      flex: 33%;
      padding: 0 10px;
    }

    .result {
      @include transition-ease;

      &:hover {
        opacity: 0.8;
      }

      &-img {
        border-radius: 10px;
        width: 100%;
        max-width: 400px;

        height: auto;
        display: block;
      }

      &-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 2px;

        .source-icon {
          margin-right: 5px;
          width: 18px;
          height: 18px;
        }
        p {
          margin: 0;
          font-size: 14px;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-details {
        margin-bottom: 10px;
        p {
          margin: 0;
          max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

.generate-license-btn-modal {
  .code-container {
    position: relative;
    margin: 20px;
    .copy-btn {
      position: absolute;
      bottom: -25px;
      right: 0px;
      padding: 3px 7px;
      cursor: pointer;
      border: 1px solid #d8d8d8;
      border-radius: 7px;
      font-size: 12px;
    }
    pre {
      background-color: #f4f4f4;
      padding: 10px;
      border-radius: 5px;
      overflow-x: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .tikbox-assets {
    &__content {
      .assets-container {
        padding: 10px;

        &__header {
          flex-direction: column;
          .status-filter {
            flex-direction: column;
            .dropdown-container {
              width: 100%;
            }
            .search-input {
              width: 100%;
              margin-top: 16px;

              input {
                width: 100%;
              }
            }
          }
        }
        .assets-table {
          &__header {
            display: none;
          }
          &__row {
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 0;
            margin-bottom: 16px;
            .tikbox-col {
              width: 100%;
              height: 100%;
              border-left: 1px solid #e8e8e8;
              border-right: 1px solid #e8e8e8;
              border-bottom: 1px solid #e8e8e8;
              padding: 12px;
            }
            .tikbox-col:first-child {
              width: 100%;
              border-top: 1px solid #e8e8e8;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
            .tikbox-col:last-child {
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
            }
            .tikbox-col {
              &__content {
                display: flex;
                justify-content: space-between;
                .title {
                  display: block;
                }
              }
              .button {
                width: 100%;
                height: 40px;
              }
            }
          }
          &__body {
            border: 0;
            .assets-table__row {
              height: 275px;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}
