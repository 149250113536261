// GEBERAL
.tikbox-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

// HOME
.tikbox-home {
  &__content {
    display: flex;
    height: 100%;

    .summary {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #e8e8e8;
      .single {
        border-right: 1px solid #e8e8e8;
        width: 33.33%;
        padding: 16px 20px;

        &:last-child {
          border-right: none;
        }

        .status {
          font-size: 16px;
          font-weight: 700;
          margin: 0;

          &.draft {
            color: #cc8316;
          }
          &.signed {
            color: #1f8505;
          }
          &.royalties {
            color: #0f27fd;
          }
        }
        .status-value {
          margin: 0;
          color: #231f20;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    .contract {
      padding: 16px 20px;

      p {
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      .title {
        font-size: 24px;
        font-weight: 700;
      }
      .assets-upload-section {
        .assets-type-selection {
          .labelling-options {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

.industry-details-form {
  h6 {
    margin-bottom: 15px;
  }
  .custom-select-dropdown {
    z-index: 10050 !important;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    button {
      width: 122px;
      height: 48px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      background-color: #0daa85;
      border: 1px solid #0daa85;
      color: #fff;
      margin-top: 10px;

      @include transition-ease;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tikbox-home {
    &__content {
      .summary {
        .single {
          padding: 10px;

          .status {
            font-size: 14px;
          }
          .status-value {
            font-size: 20px;
          }
        }
      }
      .contract {
        padding: 10px;
        .title {
          font-size: 20px;
        }
        .assets-upload-section {
          .assets-type-selection {
            .labelling-options {
              flex-direction: column;

              &__options,
              &__ai-tools {
                margin-bottom: 10px;
              }
            }
          }
          .upload-boxes {
            .upload-box {
              .upload-box-status {
                top: 75px;
              }
            }
          }
        }
      }
    }
  }
}
