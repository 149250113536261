.agreement {
  background-color: #f7f8fa;
  min-height: 100vh;
  &-header {
    width: 100%;
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #E8E8E8;
    align-items: center;
    flex-direction: column;
    background-color: #fff;

    &__logo {
      width: 10%;
      a {
        img {
          width: 100px;
        }
      }
    }

    &__sections {
      width: 80%;
      display: flex;
      padding: 0 7%;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        position: relative;
        height: 40px;

        &::after {
          content: '';
          position: absolute;
          right: -30px;
          top: 16px;
          height: 1px;
          width: 20px;
          background-color: rgb(168, 168, 168);
          display: none;
        }

        p {
          margin: 0;
          margin-left: 7px;
          font-size: 15px;
          font-weight: 500;
        }

        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #e8e8e8;
          color: rgb(52, 52, 52);
        }
      }
    }

    &__action-btns {
      width: 10%;
      display: flex;
      svg {
        margin-left: 12px;
        cursor: pointer;

        &:first-child {
          cursor: not-allowed;
        }
      }
    }
  }

  &-interface {
    background-color: #fff;
    max-width: 900px;
    margin: 29px auto;
    padding: 20px;
    border-radius: 16px;
    &-body {
      &__content {
        margin: 0 auto;
        max-width: 900px;
        padding-bottom: 50px;
  
        h2 {
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 30px;
        }
  
        h3 {
          font-weight: 500;
          font-size: 20px;
        }
  
        p {
          font-size: 18px;
          font-weight: 400;
        }
  
        .questionaire {
          margin-top: 30px;
  
          form {
            .tikbox-form-element {
              margin-bottom: 15px;
  
              .acknowledgement {
                font-weight: 500;
              }
  
              input {
                width: 20px;
                height: 20px;
  
                &.royalty {
                  width: 264px;
                  border: 1px solid #CACBD2;
                  outline: none;
                  padding-left: 5px;
                  padding-right: 5px;
                  border-radius: 8px;
  
                  &:focus {
                    border: 1px solid green;
                  }
                }
              }
  
              label {
                margin-top: 4px;
                margin-left: 10px;
                width: 90%;
  
                &.royalty, &.length {
                  font-weight: 500;
                  width: 100px;
                }
              }
  
              .length-inputs {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                .length-input-container {
                  display: flex;
                  align-items: center;
  
                  label {
                    margin-left: 10px;
                  }
                }
  
                input {
                  width: 17px;
                  height: 17px;
                }
              }
            }
          }
        }
  
        .agreement-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          
          input[type="text"], textarea {
            width: 90%;
            margin-left: 5px;
            margin-right: 5px;
          }
          textarea {
            height: 70px;
          }

          p {
            margin: 0;
          }
        }
  
        .agreement-action-btns {
          .cancel-icon {
            margin-right: 10px;
          }
        }

        .assignment-content {
          position: relative;
        }
        .cco-review {
          a.view-license-link {
            color: rgb(222, 70, 0);
            @include transition-ease;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .cco-attribution {
          .attribution-input {
            margin-bottom: 15px;
            input {
              border: 1px solid #CACBD2;
              outline: none;
              padding-left: 5px;
              padding-right: 5px;
              border-radius: 8px;
              width: 100%;
              min-width: 400px;
              height: 40px;
              &:focus {
                border: 1px solid green;
              }
            }
          }
        }
        .cco-boilerplate {
          .attribution-checkbox {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            input {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }
        }
        .license-chooser {
          display: flex;
          justify-content: space-between;
          &__decisions {
            width: 48%;
            border: 2px solid #eaeaea;
            border-radius: 10px;
            align-self: flex-start;

            &-decision {
              display: flex;
              padding: 15px;
              border-bottom: 2px solid #d8d8d8;

              &:hover {
                // border: 2px solid grey;
              }

              .decision-no {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background-color: #0daa85;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
              }
              .decision-question {
                width: calc(100% - 40px);
                &__open-title {
                  display: none;
                  font-size: 16px;
                }
                &__close-title {
                  display: block;
                  font-size: 16px;
                }
                &__selection {
                  display: block;
                  margin-bottom: 5px;
                  font-size: 12px;
                }
                &__select-options {
                  display: none;
                  margin-top: 20px;
                  margin-bottom: 20px;
                }
                &__btns {
                  display: flex;
                  display: none;
                  &-back {
                    padding: 5px 10px;
                    border-radius: 5px;
                    border: 1px solid grey;
                    margin-right: 10px;
                    @include transition-ease;
    
                    &:hover {
                      opacity: 0.9;
                    }
                    &:disabled {
                      opacity: 0.5;
                      cursor: not-allowed;
                      &:hover {
                        opacity: 0.5;
                      }
                    }
                  }
                  &-next {
                    padding: 5px 10px;
                    border-radius: 5px;
                    background-color: #0daa85;
                    border: 1px solid #0daa85;
                    color: white;
                    @include transition-ease;
    
                    &:hover {
                      opacity: 0.9;
                    }
                    &:disabled {
                      opacity: 0.5;
                      cursor: not-allowed;
                      &:hover {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }

              &.open {
                &:hover {
                  // border: inherit;
                }
                .decision-question {
                  &__open-title {
                    display: block;
                  }
                  &__close-title {
                    display: none;
                  }
                  &__selection {
                    display: none;
                  }
                  &__select-options {
                    display: block;
                  }
                  &__btns {
                    display: block;
                  }
                }
              }
              &.pending {
                background-color: #F7F7F7;
                .decision-no {
                  background-color: #e0e0e0;
                  color: black;
                }
                .decision-question {
                  &__open-title {
                    color: grey;
                  }
                  &__close-title {
                    color: grey;
                  }
                }
              }
            }
          }
          &__selection {
            width: 100%;
            align-self: flex-start;
          }
          @media (max-width: 724px) {
            flex-direction: column;

            &__decisions {
              width: 100%;
            }
            &__selection {
              width: 100%;
            }
          }

        }
      }
    }
  
    &-nav-btns {
      margin: 0 auto;
      max-width: 900px;
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
  
      button {
        border-radius: 8px;
        @include transition-ease;
        &:hover {
          opacity: 0.9;
        }
        &:first-child {
          color: #0daa85;
          border: 1px solid #0daa85;
          width: 73px;
          height: 48px;
        }
        &:last-child {
          padding-left: 10px;
          padding-right: 10px;
          min-width: 88px;
          height: 48px;
          background-color: #0daa85;
          border-color: #0daa85;
          font-weight: 700;
          color: #fff;
          margin-left: 10px;
  
          &:focus {
            outline-color: #1ca598;
          }
        }
        &:disabled,
        &[disabled]{
          border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;
        }
      }
    }
  }

  .dropdown {
    transform: rotate(-90deg);
    position: absolute;
    top: 25px;
    left: -10px;
    cursor: pointer;

    &.open {
      transform: rotate(0deg);
    }
  }
  .agreement-paragraph, .agreement-list, .legal-clause-container {
    position: relative;
    padding: 5px;

    &.full {
      background-color: #eeffea;
    }
    &.summary {
      background-color: rgb(255, 255, 234);
    }
    span.toggler {
      cursor: pointer;
      color: black;
      padding: 3px;
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
      @include transition-ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.confirm-cco-edit {
  a {
    color: rgb(222, 70, 0);
    @include transition-ease;

    &:hover {
      opacity: 0.8;
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    
    button {
      padding: 10px 15px;
      padding-bottom: 12px;
      border-radius: 5px;
      color: white;
      margin: 10px;
      @include transition-ease;

      &:hover {
        opacity: 0.9;
      }
    }
    .cancel-btn {
      background-color: grey;
    }
    .modify-btn {
      background-color: #0daa85;
    }
  }
}

@media (min-width: 1024px) {
  .agreement {
    &-header {
      height: 50px;
      flex-direction: row;
      padding: 0 20px;
      &__sections {
        flex-direction: row;
        .title {
          height: inherit;

          &::after {
            display: block;
          }
        }
        // .agreement-section:first-child {
        //   width: 22%;
        // }
        // .agreement-section:nth-child(2) {
        //   width: 75%;
        //   flex-direction: row;
        //   justify-content: space-between;
        // }
        // .agreement-section:last-child {
        //   width: 3%;
        //   display: flex;
        // }
      }
    }
  }
}