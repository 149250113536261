$primaryColor: #0F27FD;
$secondaryColor: #0daa85;
$lightColor: #EAEDF6;
$blackColor: #242D4E;
$whiteColor: #FBFBFF;
$primaryBgGradient: linear-gradient(180deg, #0F27FD 0%, #3FBAFE 100%);

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

html {
  scroll-behavior: smooth;
}

@mixin transition-ease {
  transition: all 0.5s ease-in-out;
}

@mixin transition-ease-out {
  transition: all 0.3s ease-in;
}

.tikbox-wrapper {
  max-width: 1280px;
  padding: 20px;
  margin: 0 auto;
}

.tikbox-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .tikbox-col-4, .tikbox-col-6 {
    width: 100%;
    padding: 10px;
  }

  @media only screen and (min-width: $screen-md) {
    .tikbox-col-4 {
      width: 45%;
    }
    .tikbox-col-6 {
      width: 55%;
    }
  }

  @media only screen and (min-width: $screen-lg) {
    .tikbox-col-4 {
      width: 40%;
    }
    .tikbox-col-6 {
      width: 60%;
    }
  }
}