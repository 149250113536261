
.pricing {
  &-container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 15px;
  }
  .pricing-title {
    margin-bottom: 20px;
    h2 {
      text-align: center;
      color: #404040;
    }
  }
  .pricing-tabs {
    display: flex;
    justify-content: space-between;

    &-categories {
      display: flex;
      .pricing-tab-category {
        position: relative;
        cursor: pointer;
        font-weight: bold;
  
        &:last-child {
          margin-left: 20px;
        }
        &.active, &:hover {
          color: #4954b8;
  
          &::after {
            position: absolute;
            content: '';
            top: 45px;
            width: 100%;
            height: 2px;
            background-color: #4954b8;
          }
        }
        &.disabled {
          cursor: not-allowed;
          color: #BDBDBD;
          &::after {
            background: none;
          }
        }
      }
    }
    &-duration {
      .pricing-duration-toggle {
        font-size: 12px;
        font-weight: 600;
        margin-left: 20px;
        width: 180px;
        height: 30px;
        background-color: #e8e8e8;
        border: 1px solid black;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      
        &__container {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
      
      
          .pricing-duration-switch {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            &__label {
              position: absolute;
              bottom: -7px;
              background-color: #0daa85;
              color: white;
              font-size: 8px;
              height: 14px;
              width: 80%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-left: 2px;
              padding-right: 2px;
            }
      
            &.active {
              background-color: white;
              border-top-left-radius: 20px;
              border-bottom-left-radius: 20px;
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
            }
          }
        }
      }
    }
  }
  .pricing-plans {
    border-top: 1px solid rgb(211, 211, 211);
    margin-top: 5px;
    display: flex;
    .pricing-plan {
      width: 20%;
      padding: 15px 10px;
      border-right: 1px solid rgb(211, 211, 211);

      &.popular {
        background-color: #f8f3f0;
      }

      &:last-child {
        border-right: 0;
      }
      &__top {
        height: 230px;
      }
      &__highlight {
        font-size: 12px;
        color: #0F27FD;
        font-weight: bold;
      }
      &__title {
        font-size: 20px;
      }
      &__subtitle {
        font-size: 12px;
        color: #676767;
        margin-bottom: 10px;
      }
      &__price {
        font-size: 20px;

        &-sub {
          font-size: 11px;
        }
      }
      &__price-brief1, &__price-brief2 {
        margin: 0;
        padding: 0;
        font-size: 12px;
      }
      &__price-brief2 {
        margin-bottom: 5px;
      }
      &__buy-btn {
        margin-bottom: 20px;
        background-color: #0F27FD;
        color: white;
        width: 100%;
        height: 35px;
        font-size: 12px;
        border-radius: 5px;
        @include transition-ease;

        &:hover {
          opacity: 0.8;
        }
        &:disabled {
          background-color: grey;
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            opacity: 0.5;
          }
        }
      }
      &__details-title {
        font-size: 12px;
        margin: 0;
        margin-bottom: 5px;
      }
      &__details {
        margin: 0;
        padding: 0;

        li {
          font-size: 11px;
          margin-left: 5px;
          margin-bottom: 7px;
          span {
            text-decoration: dotted;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .pricing-api {
    color: #404040;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1000px){
  .pricing {
    .pricing-plans {
      flex-wrap: wrap;
      .pricing-plan {
        width: 33%;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .pricing {
    .pricing-tabs {
      &-categories {
        display: flex;
        .pricing-tab-category {
          font-size: 12px;
        }
      }
    }
    .pricing-plans {
      flex-direction: column;
      margin-top: 10px;
      .pricing-plan {
        margin-bottom: 20px;
        width: 100%;
        &__top {
          height: inherit;
        }
      }
    }
  }
}