footer {
  background-color: #272733;
  padding: 35px 0;
}

.contianer-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

footer .content {
  max-width: 2000px;
   margin: 0 auto;
}
.tikbox-footer {
  padding-bottom: 0;
  margin-bottom: 0;
}

footer .tikbox-footer__top  {
  padding: 0 15px;
}

footer .tikbox-footer__top h6{
  font-size: 1rem;
  text-transform: uppercase;
  color: #eee;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 600;
}

footer .tikbox-footer__top p {
  font-size: 0.8rem;
  color: #ddd;
  margin-top: 0px;
  margin-bottom: 10px;
}

footer .tikbox-footer__top ul, footer .tikbox-footer__bottom ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -5px;
}

footer .tikbox-footer__top a, footer .tikbox-footer__bottom a {
  color: #5efff9;
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 15px;
}

footer .tikbox-footer__top a svg {
  font-size: 10px;
  margin-right: 5px;
}

footer .tikbox-footer__bottom a {
  font-size: 1.3rem;
}

footer .tikbox-footer__top a:hover, footer .tikbox-footer__bottom a:hover {
  color: #008080;
}

footer .tikbox-footer__middle {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  height: 75px;
}

footer .tikbox-footer__bottom {
  padding-top: 20px;
  padding-bottom: 0;
}

footer .tikbox-copyright {
  color: #eee;
}

footer .tikbox_social_links {
  display: flex;
}

footer .tikbox_social_links li {
  margin: 0 10px;
}

footer .tikbox-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer .tikbox-column {
  width: 45%;
  padding: 5px;
}

footer .tikbox-column ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .tikbox-column ul li a {
  text-decoration: none;
}

@media (min-width: 1199px) {
  footer .tikbox-column {
    width: 24%;
  }
}


@media (min-width: 700px) {
  footer .tikbox-footer__top a, footer .tikbox-footer__bottom a {
    font-size: 0.9rem;
  }

  footer .tikbox-footer__bottom a {
    font-size: 0.9rem;
  }

  footer .tikbox-footer__top h6{
    font-size: 1.1rem;
  }
  
  footer .tikbox-footer__top p {
    font-size: 0.9rem;
  }
}
