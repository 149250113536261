.privacy-policy {
  padding: 0 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  h2, h3, h4, p, li {
    color: #455880;
  }

  h2, h3, h4 {
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 1.2em;
    font-size: 16px;
    line-height: 28px;
    a {
      color: #0F27FD;
      font-weight: 500;
    }
  }

  &__table {
    @media (max-width: 900px) {
      overflow: scroll; 
    }
    &-content {
      min-width: 800px;
    }
    p {
      margin-bottom: 0px
    }
    &.definitions-interpretation {
      h4 {
        width: 30%;
      }
      p {
        padding-right: 5px;
        padding-left: 5px;
        width: 70%;
        margin: 0;
      }
    }
    &.data-collection{
      .privacy-policy__table-row {
        align-items: flex-end;

        p {
          padding-left: 3px;
          padding-right: 3px;
        }
      }

      p {
        font-size: 14px;
      }
      justify-content: space-between;
      p {
        width: 16%;
      }
    }
    &.personal-data {
      .privacy-policy__table-row {
        p {
          width: 50%;
          padding-left: 3px;
          padding-right: 3px;
        }
      }
    }
    &.cookies {
      .privacy-policy__table-row {
        p {
          padding-left: 5px;
          padding-right: 5px;
        }
        p:first-child {
          width: 30%;
        }
        p:nth-child(2) {
          width: 50%;
        }
        p:last-child {
          width: 20%;
        }
      }
    }
    &.cookies-2 {
      .privacy-policy__table-row {
        p {
          padding-left: 5px;
          padding-right: 5px;
        }
        p:first-child {
          width: 30%;
        }
        p:nth-child(2) {
          width: 15%;
        }
        p:nth-child(3) {
          width: 30%;
        }
        p:nth-child(4) {
          width: 15%;
        }
        p:last-child {
          width: 10%;
        }
      }
    }

    &-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }


  ul {
    list-style: disc;
    li {
      margin-bottom: 10px;
    }
  }
}