.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #CACBD2;
  background-color: #FCFDFF;
  width: 256px;
  height: 170px;
  border-radius: 8px;
  padding: 16px;
  position: relative;

  &-area {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    font-size: 12px;
  }
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    &__size {
      margin: 0;
      font-size: 12px;
      color: #565656;
      display: none;
    }
  }
  &__btn {
    margin: 0;
    width: 120px;
    height: 30px;
    border: 1px solid #0daa85;
    border-radius: 8px;
    font-size: 12px;
    color: #0daa85;
    margin-bottom: 8px;
    font-weight: 700;
    @include transition-ease;

    &:hover {
     opacity: 0.8;
    }
    &:disabled {
      background-color: #C9C9C933;
      color: #DDDDDD;
    }
  }
  &__size {
    margin: 0;
    font-size: 12px;
    color: #565656;
  }

  &.disabled {
    @include disabled-grey;
  }
  &.top {
    margin-bottom: 108px !important;
  }
  &.complete-upload {
    opacity: 1;
    cursor: default;
    background-color: #F1F6FE;
    .upload-box-status {
      .progress-details {
        margin-top: 0;
        align-items: center;
        height: 100%;
        .upload-action-btn {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .upload-box {
    width: 48%;
    margin-bottom: 15px;
  }
  .upload-box-container {
    width: 48%;
    .upload-box {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px){
  .upload-box-container {
    width: 100%;
  }
  .upload-box {
    width: 100%;
    height: 75px;
    div:first-child {
      flex-direction: row;
      justify-content: space-between;
    }
    &-left {
      flex-direction: row;
      &__title {
        margin: 0;
        margin-left: 15px;
      }
      &__size {
        display: block;
        margin-left: 15px;
      }
    }
    &__btn {
      width: 95px;
    }
    &__size {
      display: none;
    }
  }
}