.tikbox-verify {
  padding: 20px;
  .tikbox-logo-section {
    .logo {
      width: 120px;
    }
  }
  &__content {
    margin: 0 auto;
    width: 500px;
    text-align: center;
    margin-top: calc(50vh - 200px);
    margin-bottom: calc(50vh - 150px);

    .upload-section {
      .upload-icon {
        margin-top: 60px;
        margin-bottom: 30px;
      }
      button {
        width: 90px;
        height: 40px;
        background-color: #0daa85;
        font-weight: 700;
        color: #fff;
      }
    }
  }
}