.assets-upload-section {
  .assets-type-selection {
    p {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .select-radios-container {
      margin-top: 20px;
      .select-radios {
        display: flex;
        margin: 0;
        padding: 0;
      }
    }
    .labelling-options {
      display: flex;
      margin-bottom: 10px;
      flex-direction: column;

      &__options,
      &__ai-tools {
        margin-bottom: 10px;

        p {
          font-weight: 400;
        }
      }
    }
  }
  .upload-boxes {
    display: flex;
    margin-top: 20px;
  }
  .next-btn {
    padding: 16px 20px 25px 20px;
    .continue-btn {
      width: 100px;
      height: 48px;
      background-color: #0daa85;
      font-size: 14px;
      font-weight: 700;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      @include transition-ease;

      &:hover {
        opacity: 0.9;
      }

      &:disabled {
        background-color: #c9c9c933;
        color: #dddddd;
      }
    }
  }
}
.uploader-modal {
  &__sections {
    &-section {
      .title-section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .title {
          font-size: 16px;
          color: #565656;
          font-weight: 400;
          margin-bottom: 0;
        }
        .clear-all {
          font-size: 12px;
          color: #e94c4c;
          font-weight: 700;
          cursor: pointer;
        }
      }
      .statuses {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .upload-box-status {
          margin-top: 0;
          margin-bottom: 8px;
          position: static;
          width: 49%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .assets-upload-section {
    .upload-boxes {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 768px) {
  .assets-upload-section {
    .assets-type-selection {
      .select-radios-container {
        .select-radios {
          flex-direction: column;
        }
      }
    }
    .upload-boxes {
      flex-direction: column;
    }
    .copyright {
      padding: 15px;
    }
  }
  .uploader-modal {
    &__sections {
      &-section {
        .statuses {
          .upload-box-status {
            width: 100%;
          }
        }
      }
    }
  }
}

/* Override the color of the checked state of the antd Checkbox component */
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #231f20 !important; /* Change this to your desired color */
  border-color: #231f20 !important; /* Change this to your desired color */
}

.custom-checkbox .ant-checkbox-checked::after {
  border-color: #231f20 !important; /* Change this to your desired color */
}

/* Override the hover border color of the antd Checkbox component */
.custom-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #231f20 !important; /* Change this to your desired color */
}

/* Override the size of the antd Checkbox component */
.custom-checkbox .ant-checkbox-inner {
  width: 19px; /* Increase the width */
  height: 19px; /* Increase the height */
}

.custom-checkbox .ant-checkbox-inner::after {
  width: 9px; /* Adjust the width of the checkmark */
  height: 9px; /* Adjust the height of the checkmark */
  // transform: scale(1.5); /* Scale the checkmark */
  display: none;
}

.custom-checkbox .ant-checkbox {
  font-size: 18px; /* Adjust the font size of the label */
}
