.shareApp {
  svg {
    width: 30px;
  }
  .share-btn {
    background-color: #0daa85;
    @include transition-ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.shareIcons {
  svg {
    width: 30px;
  }
}