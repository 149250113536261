@import "mixins";
.modalApp{
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;

    @include breakpoint(mobileL) {
        min-width: initial;
      }

    h2 {
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 40px;
    }

    .content {
        width: 100%;
        border-top: 1px solid #efefef;
        padding: 30px 0px;
    }

    p.infoLines{
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        span {
            &:first-child{
                flex: 1;
            } 
           
        }

        &.copied {
            position: absolute;
            bottom: -32px;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }

    form{
        display: flex;
        flex-direction: column;
        textarea {
            border: 1px solid #e2e8f0;
            padding: 10px;
            margin: 20px 0px;
            min-height: 100px;
            outline: none;
        }
        
    }
    button{
        color: #fff;
    }
    .shareIcons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;

        img{
        width: 50px;
        cursor: pointer;
        }
    }

    .shareApp {
        display: flex;
        flex-direction: column;
        position: relative;
       button {
            margin-top: 5px;
        }
    }

}

.sidebar-back-btn {
    cursor: pointer;
}

/* CSS file */
.ant-pagination-item {
    background-color: purple; /* Change background color */
  }
  .ant-pagination-item {
    border: 1px solid #DDDDDD !important; /* Change border color */
    border-radius: 8px !important; /* Change border radius */
  }
  .ant-pagination-item-active {
    color: #fff !important; /* Change text color of active item */
    border-color: #0daa85 !important; /* Change border color of active item */
    background-color: #0daa85 !important; /* Change background color of active item */
  }
  .ant-pagination-disabled {
    color: #DDDDDD !important; /* Change text color of disabled item */
  }