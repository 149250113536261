@import './../../assets/scss/config';

@keyframes megaMenu {
  from {opacity: 0;}
  to {opacity: 1;}
}


header {
  .tikbox-promo {
    display: flex;
    background: $blackColor;
    height: 36px;
    align-items: center;
    justify-content: center;
    p {
      font-size: 12px;
      color: $whiteColor;
      font-weight: 590;
    }
  }
  .tikbox-navbar {
    height: 70px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    &__logo {
      img {
        width: 120px;
      }
    }

    &__menu {
      display: none;
      width: 50%;
      @include transition-ease;
      &-links {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          margin: 0 10px;

          &:hover {
            .title {
              &::after {
                position: absolute;
                content: "";
                left: 0;
                bottom: -5px;
                width: 100%;
                height: 1px;
                background-color: $primaryColor;
                display: block;
              }
            }
          }

          &.anchor-link a {
            font-size: 14px;
            position: relative;

            &.active, &:hover {
              color: $primaryColor;

              &::after {
                display: block;
              }
            }
          }

          &.link-btn {
            border-radius: 30px;
            height: 45px;
            width: 95px;
            padding: 0 10px;
            button {
              width: 100%;
              height: 100%;
              border: 0;
              outline: none;
              font-size: 13px;
              font-weight: 500;
            }

            &:hover {
              opacity: 0.8;
            }

            &.signin-btn {
              border: 1px solid $blackColor;

              &:focus, &:hover {
                background: #0797ac1f;
              }
            }

            &.try-btn {
              background: $primaryBgGradient;
              color: $whiteColor;
              width: 140px;

              &:hover {
                opacity: 0.8;
              }
            }
          }

          &.has-mega-menu {

            .title {
              color: $blackColor;
              position: relative;
              display: flex;
              align-items: center;

              span:first-child {
                margin-right: 7px;
              }
              .dropdown-icon { 
                @include transition-ease-out;
              }
            }
            .mega-menu {
              display: none;
              z-index: 10;
              width: 95vw;
              left: 2.5vw;
              position: absolute;
              top: 95px;
              padding-top: 0;
              background-color: transparent;
              animation-name: megaMenu;
              animation-duration: .5s;

              &.lv-1 {
                top: 45px;
              }
              &.lv-2 {
                top: 95px;
              }
              &.lv-3 {
                top: 145px;
              }
            
              .content {
                display: flex;
                flex-direction: column;

                box-shadow: 0 0 30px rgba(0,0,0,.25);
                border-radius: 25px;
                padding: 25px;
                background-color: white;
                
                .primary {
                  width: 100%;
                  padding: 10px;
                  background-color:  #F7F7F8;
                  border-radius: 20px;

                  ul {
                    display: flex;
                    flex-direction: column;
                    li {
                      display: flex;
                      padding: 10px;

                      &:hover {
                        background-color: #fff;
                        border-radius: 20px;
                      }
                      .icon-container {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: $primaryColor;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                      }
                      .text-container {
                        h3 {
                          font-size: 16px;
                          font-weight: 500;
                          color: $primaryColor;
                        }
                        p {
                          font-size: 14px;
                          color: #7A7A7A;
                        }
                      }
                    }
                  }
                }

                .secondary {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  width: 100%;
                  .sect {
                    max-width: 300px;
                    margin-top: 10px;
                    h3 {
                      color: $primaryColor;
                      font-size: 16px;
                      font-weight: 500;
                    }
                    ul {
                      li {
                        font-size: 14px;
                        color: #7A7A7A;
                        font-weight: 500;
                        margin-top: 8px;
                        margin-bottom: 8px;
                        @include transition-ease;

                        &:hover {
                          opacity: 0.85;
                          a {
                            color: rgb(15, 95, 114);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .mega-menu {
                display: block;
              }
              .title {
                .dropdown-icon {
                  rotate: 180deg;
                }
              }
            }
            &.mega-menu-relative {
              position: static;

              .mega-menu {
                width: 95vw;
                left: 2.5vw;
                position: absolute;
              }
            }
            
            @media only screen and (min-width: $screen-lg) {
              .mega-menu {
                width: 950px;
                left: calc(50vw - 475px);
                top: 95px !important;
                padding-top: 45px;
                .content {
                  flex-direction: row;

                  .primary {
                    width: 350px;
                  }
                  .secondary {
                    width: calc(80vw - 295px);
                    flex-direction: row;
                    padding: 0 25px 25px 25px;

                    .sect {
                      margin-top: 0px;
                    }
                  }
                }
              }
              &.mega-menu-relative {
                position: relative;
  
                .mega-menu {
                  width: 350px;
                  left: -100px;
                  top: 20px !important;
                }
              }
            }
            @media only screen and (min-width: $screen-xl) {
              .mega-menu {
                width: 1100px;
                left: calc(50vw - 550px);
              }
            }
          }
        }
      }

      &.open {
        @media only screen and (max-width: $screen-md) {
          position: absolute;
          top: 106px;
          z-index: 2;
          left: 0;
          display: block;
          width: 100%;
          padding-bottom: 20px;
          background-color: $whiteColor;

          .tikbox-navbar__menu-links {
            flex-direction: column;

            &__link {
              padding-bottom: 10px;
              padding-top: 10px;
              margin-bottom: 10px;
            }
          }
        }
       
      }
    }
  }
}

@media only screen and (min-width: $screen-md) {
  header {
    .tikbox-promo {
    }
    .tikbox-navbar {
      height: 100px;
      padding: 0 40px;

      &__logo {
        img {
          width: 150px;
        }
      }
  
      &__toggler {
        display: none;
      }
  
      &__menu {
        width: 70%;
        display: block;
        &-links {
  
          &__link {
            font-size: 12px;

            &.anchor-link {
              &::after {
                bottom: -5px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  header {
    .tikbox-promo {
    }
    .tikbox-navbar {
      padding: 0 10px;
      max-width: 950px;
      margin: 0 auto;

      &__logo {
        img {
          width: 130px;
        }
      }
  
      &__menu {
        width: 80%;
        &-links {
  
          &__link {
            font-size: 18px;
  
            &.link-btn {
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-xl) {
  header {
    .tikbox-navbar {
      padding: 0 30px;
      max-width: 1100px;
      &__logo {
        img {
          width: 180px;
        }
      }
  
      &__menu {
        width: 70%;
        &-links {
  
          &__link {
  
            &.link-btn {
            }
          }
        }
      }
    }
  }
}