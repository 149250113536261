@import "mixins";

body {
  //overflow: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #231F20;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.top-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.main-area {
  padding: 20px;
  position: absolute;
  width: 100%;
  height: calc(100% - 65px);
  top: 65px;
  left: 0;
  overflow: auto;

  &.home-area {
    max-height: 840px;
    z-index: 1;
    .selected-category {
      color: #48bb78;
    }
  }
}

.recorder-wrapper {
  width: 100%;
  height: 100vh;
  min-height: calc(100% - 100px);

  * {
    box-sizing: inherit;
  }

  .recorder-timer {
    @media only screen and (min-width: 992px) {
      position: absolute;
      right: -50px;
      top: 20px;
    }
  }
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  text-align: center;

  a {
    width: 100px;
    padding: 1rem;
    color: #ffffff;
    background: #fc466b; /* fallback for old browsers */
    background: -webkit-linear-gradient(
                    to right,
                    #3f5efb,
                    #fc466b
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
                    to right,
                    #3f5efb,
                    #fc466b
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 0.5rem;
    animation: float 3s ease-in-out infinite;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0);
    transition: all 0.25s ease 0s;

    &:hover {
      opacity: 0.9;
      transition: all 0.25s ease 0s;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
    }

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-10px);
      }
      100% {
        transform: translatey(0px);
      }
    }
  }

  p {
    &:nth-of-type(2) {
      font-size: 12px;
    }
  }
}


//Landing Page

.landing {
  max-width: 1280px;

  .infoText {
    padding: 30px 0px;
  }
}


//Navigation
.signInWraper {

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  nav {
    justify-content: center !important;
  }

  .navHeader {
    width: 100%;
    max-width: 1280px;
  }
}


//SignIn SignUp
.signInContent {
  margin-top: 10px;
}


//Recorder SCreen
.kebabIcon {
  height: 35px;
}

//Icons
.resolution-info {
  p.icons {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
  }

  img {
    width: 20px;
    margin-left: 2px;
  }
}


//loading Gif
.loadingApp {
  img {
    width: 50px;
    margin-left: 5px;
  }
}


//upload Page

.buttonCar {
  position: relative;
}

.uploadPage {
  margin-top: 20px;

  .pageHeading {
    @media (max-width: 520px) {
      font-size: calc(18px + 1.5vw);
    }

  }

  .uploadCards {

    &.selected-category {
      .rounded {
        border: 1px solid rgba(72, 187, 120, 0.5)
      }
    }

    .rounded {
      border: 1px solid rgba(33, 33, 33, 0.1);
      font-weight: 600;
      padding: 0px 10px 30px;
    }

    .items {
      display: flex;
      height: 150px;
    }

    p {
      word-break: break-word;
    }
  }

  .listWrape {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    flex-wrap: wrap;

    li {
      min-width: 300px;
      @include breakpoint(mobileL) {
        min-width: 240px;
      }
      @include breakpoint(mobileSm) {
        min-width: 120px;
        width: 100%;
        max-width: 190px;
      }
      @include breakpoint(mobileXs) {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        max-width: 130px;
        margin-right: 5px;
      }

    }
  }

  .heading {
    font-size: 28px;
  }

}


//onBoard Header
.top-nav {
  &.innerNav {
    background: white;
    padding-right: 4rem;
    position: fixed;
    top: 0;
    right: 0 !important;
    left: initial;
    width: initial;
    z-index: 200;

    button {
      background: gray;
      border: 1px solid #bfbfbf;
      font-weight: 500;
      letter-spacing: 0.3px;
      color: white;
      min-width: 95px;

      &:hover {
        cursor: pointer;
        color: white;
        background: #bfbfbf;
        border: 1px solid gray;
      }
    }
  }

}

.auth-form {
  box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
  border-radius: 6px;
  overflow: hidden;
  // background-color: #f9f9f9;
  background-color: #e5e5e5;
}

.menu-signup-btn {
  background: #0f27fd;

  &:hover {
    background: #3fbafe;
  }
}