// @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

// MAIN PANEL
.tikbox-main-panel {
  width: calc(100vw - 250px);
  background-color: #f7f8fa;
  height: 100vh;
  overflow-y: overlay;
  &.full {
    width: 100vw;
  }
  &-content {
    .tikbox-main-panel__header {
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 65px;
      background-color: #fff;
      padding-right: 30px;
      // box-shadow: 0 0 10px 0 rgba(0,0,0,.05);

      &-logo {
        display: flex;
        align-items: center;
        justify-items: space-between;

        svg {
          margin-left: 20px;
          margin-right: 20px;
        }

        .logo {
          width: 120px;
        }
        .salutation {
          margin-left: 20px;
        }
      }
      &-profile {
        display: flex;
        align-items: center;
        margin-right: 5px;
        .question {
          width: 20px;
          height: 20px;
          border: 1px solid #000;
          border-radius: 50%;
          font-weight: 500;
          margin-right: 15px;
          font-size: 12px;
        }
        .profile {
          width: 30px;
          height: 30px;
          background-color: #0f27fd;
          border-radius: 50%;
          color: #fff;
          font-weight: 500;
          font-size: 12px;
          position: relative;
          cursor: pointer;
  
          &-dropdown {
            position: absolute;
            z-index: 3;
            top: 40px;
            right: 0;
            width: 150px;
            background-color: white;
            padding: 10px 0px;
            box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
            display: none;
  
            ul {
              margin: 0;
              padding: 0;
              li {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: left;
                color: black;
                height: 30px;
                padding-left: 10px;
  
                &:hover {
                  background-color: #f8f8f8;
                }
              }
            }
  
            &.open {
              display: block;
            }
          }
        }
      }
    }

    .tikbox-main-panel__children {
      padding: 20px;

      &-content {
        border-radius: 15px;
        background-color: white;
      
      }
    }
  }
}

@media screen and (max-width: 768px){
  .tikbox-main-panel {
    width: 100vw;

    .overlay {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &.full {
      .overlay {
        display: none;
      }
    }
    &-content {
      .tikbox-main-panel__header {
        padding-right: 20px;
        &-logo {
          .salutation {
            margin-left: 15px;
          }
        }
        &-profile {
          .question {
            margin-right: 10px;
          }
        }
      }
  
      .tikbox-main-panel__children {
        padding: 10px;
  
        &-content {
          padding: 10px;
        }
      }
    }
  }
}