.dmca {
  padding: 0 30px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  h2, h3, h4, p, li {
    color: #455880;
  }
  h2, h3 {
    margin-bottom: 15px;
  }

  h2, h3, h4 {
    font-weight: 700;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 1.2em;
    font-size: 16px;
    line-height: 28px;
    a {
      color: #0F27FD;
      font-weight: 500;
    }
  }


  ul {
    list-style: disc;
    li {
      margin-bottom: 10px;
    }
  }
}