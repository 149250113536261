@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import "./scss/common";
@import "./scss/animations";
@import "./scss/components/common/select-radio";
@import "./scss/components/upload-box";
@import "./scss/components/common/upload-box-status";
@import "./scss/components/common/assets-upload-section";
@import "./scss/components/common/modal";
@import "./scss/pages";
@import "./scss/pages/pricing";
@import "./scss/pages/user/home";
@import "./scss/pages/user/assets";
@import "./scss/pages/user/agreement";
@import "./scss/pages/user/user-verify";
@import "./scss/pages/user/template-agreement";
@import "./scss/pages/admin/invite-members";
@import "./scss/pages/terms/privacy-policy";
@import "./scss/pages/terms/terms-conditions";
@import "./scss/pages/terms/dmca";
@import "./scss/pages/watermark/watermark";

//@import "./scss/mobile_editor";
.text-timeline {
    min-height: 700px !important;
  }