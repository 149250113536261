.tikbox-select-radio {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  &:first-child {
    margin-right: 20px;
  }
  .radio-container {
    width: 25px;
    height: 25px;
    .radio {
      position: relative;
      width: 20px;
      height: 20px;
      background-color: #f9fafb;
      border: 1px solid #dddee1;
      border-radius: 50%;
      margin-right: 8px;
      cursor: pointer;
      font-size: 14px;
    }
    &:hover {
      .radio {
        border: 1px solid #0a82fb;
      }
    }
  }

  &.selected {
    .radio {
      background-color: #231f20;
      border: none;

      &::after {
        content: '';
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        top: 5px;
        left: 5.3px;
        width: 10px;
        height: 10px;
        display: none;
      }
    }
    &:hover {
      .radio {
        border-color: #231f20;
      }
    }
  }
  &.disabled {
    @include disabled-grey;
  }
}
