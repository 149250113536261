// #24ddcb
// #231f20
.landing-page {
  .masthead {
  .site-logo-link {
    .site-logo {
      width: 100%;
    }
    .logo-text {
      font-weight: 500;
    }
  }
}

  .home-content {
    background-repeat: no-repeat;
    background-size:cover;
    background:linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), url(../../assets/images/landingPage2.jpg);
    background-position: center;
    min-height: 100vh;
    .landing-page-bg {

      @media (min-width: 1024px) {
        height: 500px;
      }
    }
    .bg-img-text {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      margin-bottom: 50px;

      @media (min-width: 1024px) {
        margin-bottom: inherit;
      }
    }
  }
}