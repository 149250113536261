.watermark {
  background-image: url(../../../images/public-watermark.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
}

.modalApp.watermark-modal {
  h2 {
    text-align: center !important;
  }
  .content {
    border-top: 0 !important;
  }
}

.watermark-modal-content {
  &__step-one {
    button {
      background-color: #0daa85;
      padding: 10px 15px;
      border-radius: 5px;
      @include transition-ease;

      &:hover {
        opacity: 0.9;
      }
    }
  }
  &__step-two {
    form {
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .watermark-input {
        width: 256px;
        height: 40px;
        border: 1px solid #ccc;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        outline: none;
      }
      button {
        color: #0daa85;

        &.submit {
          background-color: #0daa85;
          color: white;
          padding: 5px 15px;
          @include transition-ease;

          &:hover {
            opacity: 0.9;
          }
        }
        &.disabled {
          @include disabled-grey;
        }
      }
    }
  }
  &__step-three {
    button {
      background-color: #0daa85;
      padding: 10px 15px;
      border-radius: 5px;
      margin: 0 auto;
      @include transition-ease;

      &:hover {
        opacity: 0.9;
      }
    }
  }
  &__step-one, &__step-two, &__step-three {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      text-decoration: underline;
    }

    ul {
      list-style: disc;
    }
  }
}