@keyframes nudge {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Adjust the value based on the direction and intensity */
  }
}

.tikbox-template-agreement {
  background-color: #f3f3f3;
  &__content {
    display: flex;
    height: 100%;

    .template-agreement-container {
      .title {
        border-bottom: 1px solid #E8E8E8;
        padding: 20px 20px;

        h2 {
          font-size: 24px;
          font-weight: 700;
          margin: 0;
        }
      }
      &__section {
        display: flex;
        .agreement-templates__list {
            padding: 20px 40px;
            border-right: 1px solid #E8E8E8;
            width: 45%;
            h4 {
              font-size: 16px;
            }
            p {
              font-size: 14px;
              margin: 0;
              font-weight: 400;
              margin-bottom: 8px;
              margin-top: 20px;
            }
            .cco-btn {
              margin: 0;
              margin-top: 5px;
              cursor: pointer;
              @include transition-ease;

              &:hover {
                opacity: 0.9;
              }
            }
            .cco-preview {
              .remove-btn {
                color: red;
                cursor: pointer;
                margin-left: 10px;
              }
            }
            .agreement-templates-select {
              display: flex;
              .dropdown-container {
                width: 290px;
                height: 48px;
                border: 1px solid #CACBD2;
                font-weight: 400;
                border-radius: 8px;
                font-size: 12px;
                position: relative;
                cursor: pointer;
                padding: 10px 12px 10px 16px;
                margin-right: 7px;
                align-items: center;
                justify-content: center;
                display: flex;
          
                .status-text {
                  width: 100%;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                }
          
                &__dropdown {
                  position: absolute;
                  top: 50px;
                  left: 0;
                  width: 100%;
                  background-color: white;
                  box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
                  display: none;
                  z-index: 2;
                  padding: 16px;
          
                  ul {
                    padding: 0;
                    margin: 0;
                    li {
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: left;
                      color: black;
                      height: 30px;
          
                      &:hover {
                        background-color: #f8f8f8;
                      }
                    }
                  }
          
                  &.open {
                    display: block;
                  }
                }
              }
              .add-icon {
                height: 48px;
                width: 48px;
                border: 1px solid #0daa85;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include transition-ease;

                &:hover {
                  opacity: 0.9;
                }
              }
            }
            .cco-license-list-title {
              margin-top: 20px;
              margin-bottom: 40px;
            }
            .cco-license-list {
              margin-left: 0;
              padding-left: 0;

              li {
                margin-bottom: 10px;
                span:first-child {
                  display: flex;
                  align-items: center;
                }
                span {
                  margin-left: 3px;
                  margin-right: 3px;

                  &.caption {
                    margin-left: 65px;
                    display: flex;
                    font-size: 13px;
                    font-weight: 600;
                  }

                  img {
                    width: 25px;
                    height: 25px;
                  }
                }
              }
            }
        }
       .agreement-templates__upload {
        width: 55%;
        padding: 20px 40px;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        position: relative;

        .upload-pointer-arrow {
          display: none;
          position: absolute;
          z-index: 2;
          animation: nudge 1.5s infinite;
          top: 200px;
          left: -40px;
        }

        &.active {
          opacity: 1;
          pointer-events: all;

          .upload-pointer-arrow {
            display: block;
          }
        }

        .assets-upload-section {
          .upload-boxes {
            flex-wrap: wrap;

            .upload-box {
              width: 45%;
              margin-bottom: 16px;
            }
            .upload-box-container {
              width: 45%;
              .upload-box {
                width: 100%;
              }
            }
          }
        }
       }
      }
    }
  }
}
.template-agreement-form {
  .input-container {
    margin-bottom: 32px;
    &__label {
      margin: 0;
      margin-bottom: 10px;
    }
    input {
      border-radius: 8px;
      border: 1px solid #CACBD2;
      outline: none;
      height: 48px;
      width: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .action-btns {
    display: flex;
    button {
      width: 122px;
      height: 48px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      @include transition-ease;
      &:hover {
        opacity: 0.9;
      }
      &.use-later-btn {
        color: #0daa85;
        border: 1px solid #0daa85;
        margin-right: 11px;
      }
      &.use-now-btn {
        background-color: #0daa85;
        color: #0daa85;
        border: 1px solid #0daa85;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1150px){
  .tikbox-template-agreement {
    &__content {
  
      .template-agreement-container {
        &__section {
          .agreement-templates__list {
            width: 45%;
          }
          .agreement-templates__upload {
            width: 55%;
            .assets-upload-section {
              .upload-boxes {
                .upload-box-container {
                  width: 100%;
                }
                .upload-box {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){ 
  .tikbox-template-agreement {
    &__content {
  
      .template-agreement-container {
        .title {
          padding: 10px;
        }
        &__section {
          flex-direction: column;
          .agreement-templates__list {
            width: 100%;
            padding: 10px;
            .agreement-templates-select {
              .dropdown-container {
                width: 100%;
              }
            }
          }
          .agreement-templates__upload {
            width: 100%;
            padding: 10px;
            .assets-upload-section {
              .upload-boxes {
                .upload-box {
                  &.top {
                    .upload-box-status {
                      top: 75px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-select-dropdown {
  z-index: 10050 !important;
}
.cco-modal-content {
  padding-left: 10%;
  padding-right: 10%;
  .workflow-next-container {
    .workflow-next-btn {
      background-color: #0daa85;
      color: white;
      padding: 10px 35px;
      border-radius: 5px;
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      @include transition-ease;
  
      &:hover {
        opacity: 0.9;
      }
    }
  }
}