// SIDEBAR
.tikbox-sidepanel {
  width: 250px;
  height: 100%;
  background-color: #fff;
  height: 100vh;
  border-right: 1px solid #E8E8E8;

  &.minimized {
    display: none;
  }
  &__content {
    padding: 12px;

    .tikbox-logo-section {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 120px;
      }

      .tikbox-hamburger {
        position: absolute;
        content: '';
        right: 0;
        top: 15px;
        height: 3px;
        width: 30px;
        background-color: red;

        &::before {
          position: absolute;
          top: 7px;
          height: 3px;
          width: 20px;
          content: '';
          background-color: red;
          right: 0;
        }

        &::after {
          position: absolute;
          bottom: 7px;
          height: 3px;
          width: 30px;
          content: '';
          background-color: red;
          right: 0;
        }
      }
    }

    &-menu {
      margin-top: 30px;

      .sidepanel-menu {
        padding-left: 0px;
        .menu-item {
          color: #565656;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          a {
            display: flex;
            padding: 8px;
            svg {
              margin-right: 10px;
            }
            &:visited {
              color: inherit;
              text-decoration: none !important;
            }
          }

          &.active, &:hover {
            background-color: #EEF0FF;
            color: #0F27FD;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .tikbox-sidepanel {
    position: fixed;
    left: 0;
    top: 0;
    width: 220px;
    z-index: 5;
    &__content {
  
      &-menu {
  
        .sidepanel-menu {
          .menu-item {
            font-size: 14px;
          }
        }
      }
    }
  }
}