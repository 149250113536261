.tikbox-invite-members {
  &__content {
    display: flex;
    height: 100%;

    .invite-members-container {
      padding: 16px 20px;
      .title {
        font-size: 24px;
        font-weight: 700;
      }
      form {
        max-width: 500px;
        .tikbox-form-element {
          input {
            border: 1px solid #CACBD2;
            border-radius: 8px;
            outline: none;
            width: 100%;
            height: 35px;
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 15px;
          }
          button {
            font-size: 14px;
            font-weight: 700;
            background-color: #0daa85;
            width: 100px;
            height: 35px;
            border-radius: 5px;
            color: white;
            @include transition-ease; &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .tikbox-invite-members {
    &__content {
      .invite-members-container {
        padding: 10px;
        .title {
          font-size: 20px;
        }
      }
    }
  }
}