.tikbox-user-verify {
  &__content {
    display: flex;
    height: 100%;
    .verify-container {
      padding: 16px 20px;
      &__section {
        display: flex;
        justify-content: space-between;
        h2.title {
          font-size: 24px;
          font-weight: 700;
        }
        h4 {
          font-size: 14px;
          font-weight: 400;
        }
        .verify-doc {
          width: 69%;
          .verify-upload-preview {
            height: 500px;
            background-color: #f8f9ff;
            width: 100%;
            display: flex;
            border-radius: 8px;
            align-items: center;
            flex-direction: column;
            padding: 8px;
            .verify-upload {
              width: 100%;
              height: 64px;
              border: 1px dashed #CACBD2;
              border-radius: 8px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: 5%;
              padding-right: 5%;
              .text-icon {
                display: flex;
                align-items: center;
                svg {
                  margin-right: 12px;
                }
                p {
                  margin: 0;
                  padding: 0;
                  font-size: 14px;
                  font-weight: 700;
                }
              }
              button {
                width: 127px;
                height: 32px;
                border: 1px solid #0daa85;
                border-radius: 8px;
                color: #0daa85;
                font-size: 12px;
                font-weight: 700;
                @include transition-ease;

                &:hover {
                  opacity: 0.9;
                }
              }
            }
            .verify-preview {
              padding-top: 15px;
              padding-bottom: 15px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              p {
                width: 198px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
        }
        .verify-assets {
          width: 29%;

          .verify-asset {
            .asset-empty {
              background: #F4F4F4;
              width: 168px;
              height: 118px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #848484;
              font-size: 14px;
              cursor: not-allowed;
            }
          }
        }
      }
      .agree-btn {
        background-color: #0daa85;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        width: 141px;
        height: 40px;
        border-radius: 8px;
        margin-top: 24px;
        @include transition-ease;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .tikbox-user-verify {
    &__content {
      .verify-container {
        padding: 10px;
        &__section {
          flex-direction: column;
          .verify-doc {
            width: 100%;
            .verify-upload-preview {
              .verify-upload {
                .text-icon {
                  p {
                    font-size: 12px;
                  }
                }
                button {
                  width: 100px;
                  height: 28px;
                }
              }
            }
          }
          .verify-assets {
            width: 100%;
          }
        }
      }
    }
  }
}