.tikbox-signup {
  /* Dark shadow background out of the modal */
  .darkBG {
    background-color: rgba(222, 208, 230, 0.5);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    }

  /* Modal window */
  .centered {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: none;
    z-index: 10;
  }
  
  .modal {
    min-width: 100vw;
    height: 95vh;
    left: calc(50% - 80vw/2);
    top: calc(50% - 80vh/2);

    background: #FFFFFF;
    border-radius: 36px 36px 0 0;
    display: flex;
  }

  /* Surfer image at the left */
  .surfer-image {
    display: none;
    width: 35%;
    height: 100%;

    border-radius: 36px 0px 0px 36px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  /* Form */
  .form {
    width: 100%;
    height: 100%;
    left: 447px;
    top: 0px;

    border-radius: 0px 36px 36px 0px;

    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: normal;

    /* Sign up to tikbox Studio */
    h1 {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 31px;
      /* identical to box height */

      /* Black */
      color: #242D4E;
    }

    /* Labels */
    p {
      margin-top: 18px;
      margin-bottom: 15px;

      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
  
      color: #313131;
    }

    .input-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .input-col {
        width: 100%
      }
    }

    /* Privacy & Policy */
    .privacy {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
  
      color: #A3A3A3;
    }

    /* Input fields */
    input:not([type="checkbox"]) {
      width:100%;
      height: 49px;
      padding-left: 22px;
      padding-right: 22px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
  
      color: #313131;
      background: #F2F2F2;
    }

    /* Error message */
    .error {
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      
      color: #d9534f
    }

    /* Two colunms */
    .col1 {
      position: relative;
      left: 0px;
      width: 276px;
    }
    .col2 {
      position: relative;
      left: 309px;
      top: -107px;
      width: 276px;
    }

    button {
      border-radius: 5px;
    }

    /* Create Account button */
    button.submit-disabled {
      width: 100%;
      height: 49px;
      margin-top: 18px;
      margin-bottom: 18px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      text-align: center;
  
      color: #FFFFFF;
      background: linear-gradient(184.5deg, #A3A3A3 3.65%, #f2f2f2 146.65%);
    }
    button.submit-enabled {
      width: 100%;
      height: 49px;
      margin-top: 18px;
      margin-bottom: 18px;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      text-align: center;
  
      color: #FFFFFF;
      background: linear-gradient(184.5deg, #0F27FD 3.65%, #3FBAFE 146.65%);
    }

    /* Already a member? Sign in */
    .hint {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
  
      color: #A3A3A3;

      .link {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-decoration: underline;
        color: #313131;
        cursor: pointer;
        @include transition-ease;
  
        &:hover {
          color: #000000;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .tikbox-signup {
    .modal {
      min-width: 80vw;
      height: 80vh;
      border-radius: 36px;
      position: static;
    }
    .surfer-image {
      width: 40%;
      display: block;
    }
    .centered {
      top: 50%;
      left: 50%;
      bottom: inherit;
      transform: translate(-50%, -50%);
    }
    .form {
      width: 60%;
      padding: 40px;
      justify-content: center;
      &.watermark-signup {
        width: 100%;
        padding: 20px;
      }

      h1 {
        font-size: 26px;
      }
      
      p {
        margin-top: 22px;
        margin-bottom: 18px;
      }

      .input-row {
        flex-direction: row;
        justify-content: space-between;
  
        .input-col {
          width: 48%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .tikbox-signup {
    .surfer-image {
      width: 35%;
    }
    .form {
      width: 65%;
      padding: 70px;

      &.watermark-signup {
        width: 100%;
        padding: 40px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .tikbox-signup {
    .modal {
      min-width: 50vw;
      width: 1000px;
      top: calc(50% - 80vh/2);
    }
    .form {
      padding-left: 92px;
      padding-right: 96px;
      padding-top: 83px;
      padding-bottom: 83px;

      &.watermark-signup {
        width: 100%;
        padding: 30px;
      }
    }
  }
}
