.upload-box-status {
  position: absolute;
  left: 0;
  top: 170px;
  width: 100%;
  height: 68px;
  border: 1px solid #CACBD2;
  border-radius: 8px;
  margin-top: 20px;
  padding: 0px 12px;
  .progress-details {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .upload-status {
      display: flex;

      .uploading-icon {
        width: 32px;
        height: 32px;
        margin-top: 5px;
      }

      .upload-text {
        margin-left: 8px;
        p {
          margin: 0;
          font-size: 14px;
    
          &:first-child {
            font-weight: 700;
          }
          &:nth-child(2) {
            font-size: 13px;

            span:first-child {
              margin-right: 5px;
            }
          }
        }
      }

    }
    .upload-action-btn {
      cursor: pointer;
      margin-top: 15px;

      .view-details-btn {
        font-size: 12px;
        color: #0daa85;
        font-weight: 700;
      }
    }
  }
  .progress-bar {
    width: 100%;
    position: relative;
    background-color: #E8E8E8;
    height: 2px;
    margin-top: 8px;

    &__progression {
      @include transition-ease;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 30%;
      height: 100%;
      background-color: #0F27FD;
    }
  }
}